import snsWebSdk, { SnsWebSdk } from "@sumsub/websdk";
import { post } from "./makeRequest";
import { ApiResponse } from "interfaces";

/**
 * @param accessToken - access token that you generated on the backend
 * @param applicantEmail - applicant email (not required)
 * @param applicantPhone - applicant phone (not required)
 * @param customI18nMessages - customized locale messages for current session (not required)
 */

interface ILaunchArgs {
  accessToken: string;
  applicantEmail: string;
  applicantPhone: string;
  customI18nMessages?: any;
  theme: "dark" | "light";
  containerEl?: string;
}

interface Callbacks {
  onIdStepComplete?: () => Promise<void>;
  onIdCheckError?: () => Promise<void>;
}

export function launchSumsubWebSdk(opts: ILaunchArgs, callbacks?: Callbacks): SnsWebSdk {
  const { accessToken, applicantEmail, applicantPhone, theme, containerEl } = opts;
  let snsWebSdkInstance = snsWebSdk
    .init(
      accessToken,
      // token update callback, must return Promise
      // Access token expired
      // get a new one and pass it to the callback to re-initiate the WebSDK
      () => getSumsubNewAccessToken()
    )
    .withConf({
      lang: "en", //language of WebSDK texts and comments (ISO 639-1 format)
      email: applicantEmail,
      phone: applicantPhone,
      theme,
    })
    .withOptions({ addViewportTag: false, adaptIframeHeight: true })
    // see below what kind of messages WebSDK generates
    .on("idCheck.onStepCompleted", (payload) => {
      callbacks?.onIdStepComplete?.();
      console.log("onStepCompleted", payload);
    })
    .on("idCheck.onError", (error) => {
      callbacks?.onIdCheckError?.();
      console.log("onError", error);
    })
    .build();

  // you are ready to go:
  // just launch the WebSDK by providing the container element for it
  // snsWebSdkInstance.launch("#sumsub-websdk-container");
  snsWebSdkInstance.launch(containerEl ?? "#bitmama__sumsub");
  return snsWebSdkInstance;
}

export async function getSumsubNewAccessToken(kycLevel: "two" | "three" = "two") {
  const result = await post<ApiResponse<string>, { kycLevel: string }>("/verification/generate-access-token", { kycLevel });
  console.log("getNewAccessToken", result);
  return result?.data!;
  //   return Promise.resolve(newAccessToken); // get a new token from your backend
}
