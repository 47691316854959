import { CreditCardBrand } from "components";
import { useAppConfig } from "contexts/appconfig.context";
import { intersectionBy } from "lodash";
import { useMemo } from "react";
import { commaStrToArr } from "utils";

type CardProviderType = { brand: CreditCardBrand; provider: string; value: string; label: string; required_kyc_level: number };
const providers: CardProviderType[] = [
  {
    brand: "visa",
    provider: "p4",
    value: "visa",
    label: "Visa",
    required_kyc_level: 3,
  },
  {
    brand: "mastercard",
    provider: "p3",
    value: "mastercard",
    label: "Mastercard",
    required_kyc_level: 3,
  },
  {
    brand: "visa",
    provider: "p5",
    value: "visa-ii",
    label: "Visa",
    required_kyc_level: 2,
  },
];

export function useCardProviders() {
  const { appfigs } = useAppConfig();
  const { cardConfig } = appfigs ?? {};

  const enabled_options = useMemo(() => {
    const enabled_providers = commaStrToArr(cardConfig?.enabledCreateProviders ?? "p3");
    const intersections = intersectionBy(
      providers,
      enabled_providers.map((p) => ({ provider: p })),
      "provider"
    );

    return intersections;
  }, [cardConfig]);

  const minimum_required_kyc_level = useMemo(() => {
    const min = enabled_options.reduce((acc, curr) => {
      if (acc === -1 || acc > curr?.required_kyc_level) {
        acc = curr?.required_kyc_level || -1;
        return acc;
      }
      return acc;
    }, -1);

    return min;
  }, [enabled_options]);

  const options = useMemo(() => {
    const opts = enabled_options.filter((eo) => minimum_required_kyc_level >= eo?.required_kyc_level);
    return opts;
  }, [enabled_options, minimum_required_kyc_level]);

  return { providers: options, minimum_required_kyc_level };
}
