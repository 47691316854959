import {
  Box,
  BoxProps,
  Button,
  Flex,
  Heading,
  HStack,
  Stack,
  Text,
  useColorMode,
  useColorModeValue,
  useDisclosure,
  useRadio,
  useRadioGroup,
  UseRadioProps,
  VStack,
} from "@chakra-ui/react";
import { VisaSVG, MasterSVG } from "assets";
import { CreditCardBrand, useModalView, ViewSwitcherChildProps } from "components";
import { useDefaultStyle, useCardProviders } from "hooks";
import { PropsWithChildren, useMemo } from "react";
import { commaStrToArr, switchStyle } from "utils";
import { StepProps } from "./RequestVirtualCard";
import CardCreationNotice from "ui/Cards/Modals/CardCreationNotice";
import { useAppConfig } from "contexts/appconfig.context";

interface StepOneProps extends StepProps, ViewSwitcherChildProps {}

export default function StepOne(props: StepOneProps) {
  const { state, set } = props;

  const { hasNext, onNext } = useModalView();

  const { appfigs } = useAppConfig();
  const { cardConfig } = appfigs ?? {};

  const { providers: options } = useCardProviders();
  // const { profile } = useSelector(selectUser);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const color = useColorModeValue("gray.600", "grey.100");

  // const options = useMemo(() => {
  //   const enabled_providers = commaStrToArr(cardConfig?.enabledCreateProviders ?? "p3");
  //   const intersections = intersectionBy(
  //     providers,
  //     enabled_providers.map((p) => ({ provider: p })),
  //     "provider"
  //   );

  //   return intersections;
  // }, [cardConfig]);

  console.log("Card options", options, cardConfig?.enabledCreateProviders);

  // const kyc_level = useMemo(() => {
  //   const level = profile?.kycLevel ?? "one";
  //   const map: Record<string, number> = {
  //     one: 1,
  //     two: 2,
  //     three: 3,
  //   };
  //   return map[level];
  // }, [profile]);

  const { getRootProps, getRadioProps } = useRadioGroup({
    name: "credit-card-brand-radio",
    defaultValue: `${state?.brand},${state?.cardValue},${state?.cardProvider}`,
    onChange: (value: string) => {
      const [brand, cardValue, cardProvider] = value.split(",") as [CreditCardBrand, string, string];
      set({ brand, cardValue, cardProvider });
    },
  });

  const group = getRootProps();

  const desc = useMemo(() => {
    const map: Record<string, string> = {
      // [state?.brand!]: `${capitalize(state?.brand ?? "mastercard")} card provider is not available at the moment`,
      [state?.brand!]: `The selected card provider is not available at the moment`,
    };
    return map[state?.brand ?? "mastercard"];
  }, [state]);

  const brand_provider = useMemo(() => {
    // const map: Record<NonNullable<typeof state.brand>, string> = {
    //   visa: "p4",
    //   mastercard: "p3",
    // };
    // return map[state?.brand ?? "mastercard"];

    const selected_option = options.find((o) => o?.value === state?.cardValue);
    console.log("Selected Brand", selected_option);
    return selected_option?.provider ?? "p3";
  }, [state, options]);

  const is_brand_provider_available = useMemo(
    () => commaStrToArr(cardConfig?.enabledCreateProviders ?? "p3").includes(brand_provider),
    [cardConfig, brand_provider]
  );

  const handleClick = (brand: CreditCardBrand) => {
    set({ brand });
    if (!is_brand_provider_available) onOpen();
    else onNext();
  };

  return (
    <Box py="40px">
      <Flex flexDir={{ base: "column", "2sm": "row" }}>
        <Heading as="h6" fontSize="md" fontWeight="600" color={color}>
          Step 1
        </Heading>
        <Text ml={{ "2sm": "28px !important" }} fontSize="14px" fontWeight="500">
          Select a card type to continue
        </Text>
      </Flex>

      <HStack justifyContent="center" mt="60px" gridGap="4px" {...group}>
        {options.map(({ brand, value, provider, label, required_kyc_level }) => {
          const radio_value = brand.concat(`,${value},${provider}`);
          const radio = getRadioProps({ value: radio_value });
          // if (kyc_level <= required_kyc_level) return <></>;
          return (
            <CreditCardBrandRadio cardBrand={brand} cardValue={value} key={value} {...radio}>
              {label}
            </CreditCardBrandRadio>
          );
        })}
      </HStack>

      <Stack mt="0px" px="4px" py="42px" overflow="hidden">
        <Button
          my="22px !important"
          minW="320px"
          w="320px"
          mx={`calc((384px - 320px) / 2) !important`}
          disabled={!hasNext || !state?.cardValue}
          onClick={() => handleClick(state?.brand ?? "mastercard")}
        >
          Continue
        </Button>
      </Stack>

      {/* <WishlistModal isOpen={true} onClose={() => {}} /> */}

      <CardCreationNotice isOpen={isOpen} onClose={onClose} description={desc} canContinue={false} />
    </Box>
  );
}

interface CreditCardBrandRadioProps extends UseRadioProps, Omit<BoxProps, "onChange"> {
  cardBrand: CreditCardBrand;
  cardValue: string;
}

function CreditCardBrandRadio(props: PropsWithChildren<CreditCardBrandRadioProps>) {
  const { cardBrand, cardValue, children, ...xprops } = props;
  const { getInputProps, getCheckboxProps } = useRadio(props);

  const input = getInputProps();
  const checkbox = getCheckboxProps();

  const { colorMode } = useColorMode();

  const { shadow, borderColor } = useDefaultStyle();

  const styles = useMemo(() => {
    const map: Record<CreditCardBrand, { color: string }> = {
      visa: {
        color: switchStyle(colorMode, { dark: "#1434cb", light: "#1434cb" }),
      },
      mastercard: {
        color: switchStyle(colorMode, { dark: "#ff5a00", light: "#ff5a00" }),
      },
    };

    return map[cardBrand];
  }, [cardBrand, colorMode]);

  const brandIcon = useMemo(() => {
    const map: Record<typeof cardBrand, typeof VisaSVG> = {
      visa: VisaSVG,
      mastercard: MasterSVG,
    };

    return map[cardBrand];
  }, [cardBrand]);

  return (
    <Box
      as="label"
      p="20px"
      border="1px solid"
      borderColor="grey.200"
      display="flex"
      justifyContent="center"
      alignItems="center"
      borderRadius="8px"
      {...xprops}
    >
      <input {...input} />

      <HStack pos="relative">
        <Box
          {...checkbox}
          pos="absolute"
          top="10px"
          right="0px"
          borderRadius="4px"
          boxSize="14px"
          border="1px solid transparent"
          bg="#CACBCB"
          _after={{
            content: '""',
            pos: "absolute",
            boxSize: "7px",
            bg: "transparent",
            border: "2px solid #fff",
            top: "50%",
            left: "50%",
            borderRadius: "2px",
            transform: "translate(-50%, -50%)",
            transition: "all .3s cubic-bezier(0.4, 0, 0.2, 1)",
          }}
          _checked={{
            bg: styles.color,
            transition: "all .3s cubic-bezier(0.4, 0, 0.2, 1)",
          }}
          _focus={{
            shadow,
            borderColor,
            transition: "all .3s cubic-bezier(0.4, 0, 0.2, 1)",
          }}
          _hover={{
            _after: {
              border: "2px solid #fff",
              transform: "translate(-50%, -50%) scale(1.3)",
              transition: "all .3s cubic-bezier(0.4, 0, 0.2, 1)",
            },
          }}
          _active={{
            _after: {
              border: "3px solid #fff",
              transform: "translate(-50%, -50%) scale(0.8)",
              transition: "all .3s cubic-bezier(0.4, 0, 0.2, 1)",
            },
          }}
          transition="all .3s cubic-bezier(0.4, 0, 0.2, 1)"
        />
        <VStack>
          <Box id={cardBrand} boxSize="100px" as={brandIcon} />
          <Text mt="0 !important" fontFamily="var(--bitmama-fonts-heading)" fontSize="18px" fontWeight="500">
            {children}
          </Text>
        </VStack>
      </HStack>
    </Box>
  );
}
