import { Box, Button, ButtonProps, Heading, HStack, Stack, Text, useColorModeValue, useDisclosure, VStack } from "@chakra-ui/react";
// import { navigate } from "@reach/router";
import { useGetLevel2StatusQuery, useGetSumsubKycStatusQuery } from "apis";
import { Icon, IconNames, MainLayoutContainer, PageMotion, Steps, useModalView } from "components";
// import { AnimatePresence, motion } from "framer-motion";
import { useBuiltInFn, useDispatch, useSelector } from "hooks";
import { PageProps } from "interfaces";
import { createContext, useContext, useMemo, useState } from "react";
import { Level2VerificationForm, Level3VerificationForm } from "ui";
// import { VerificationStat } from "ui";
// import { currencyFormat } from "utils";

import capitalize from "lodash/capitalize";
import { getSumsubNewAccessToken, launchSumsubWebSdk, when } from "utils";
import { getProfile, selectUser } from "store/slices";
import { toLower } from "lodash";
import SumsubKycModal from "./SumsubModal";
import { SnsWebSdk } from "@sumsub/websdk";
import { useAppConfig } from "contexts/appconfig.context";

interface VerificationProps extends PageProps {}

interface IKycContext {
  refetchStatus: () => void;
}

const KycContext = createContext<IKycContext | null>(null);

export function useKyc() {
  const context = useContext(KycContext);
  if (!context)
    throw new Error("KycContext must be used within the Kyc Context Provider found at the root of the verification pages");
  return context;
}

export default function Verification(props: VerificationProps) {
  const { user } = props;
  const stroke = useColorModeValue("primary.600", "secondary.200");

  const { goBack } = useBuiltInFn();

  const { appfigs } = useAppConfig();
  const { kycConfig } = appfigs || {};

  const { profile } = useSelector(selectUser);

  // const [level] = useState(1);

  // const levelLimit = useMemo(() => {
  //   const map: Record<number, string> = {
  //     1: `${currencyFormat("usd").format(50)} per day`,
  //     2: `${currencyFormat("usd").format(20000)} per day`,
  //     3: `Unlimited`,
  //   };

  //   return map[level];
  // }, [level]);

  // const handleClick = (e: any) => {
  //   e.preventDefault();
  //   if (level !== 1) navigate(`verification/level${level}`);
  // };

  // const { profile } = useSelector(selectUser);
  const { data, refetch } = useGetLevel2StatusQuery({});

  const { data: kycStatus } = useGetSumsubKycStatusQuery({ method: kycConfig?.method || "sumsub" }, { skip: !kycConfig?.method });

  const isEmptyString = (val: string) => val === "" || val === undefined || val === null;

  console.log("KYC Status", kycStatus);

  const Level3Status = useMemo(() => {
    if (kycConfig?.method === "sumsub" || !!kycStatus) {
      if (kycStatus?.three) return kycStatus.three;
      else if (profile?.kycLevel === "three") return "approved";
      // return null;
    }

    if (user && !isEmptyString(user?.kycLevel) && user.kycLevel === "one") return null;
    if (user && !isEmptyString(user?.kycLevel) && user.kycLevel === "three") return "approved";

    const level3 = data?.find((d: any) => d?.kycLevel === "three");
    if (!level3) return null;

    console.log("level3", level3);

    if (level3?.kycLevel === "three" && user?.kycLevel !== "three") return level3?.status;
    return level3?.status;
  }, [user, data, kycConfig, profile, kycStatus]);

  const Level2Status = useMemo(() => {
    if (kycConfig?.method === "sumsub" || !!kycStatus) {
      if (kycStatus?.two) return kycStatus.two;
      // since the kycLevel isn't a number, we need to check if the kycLevel is either of two or three.
      else if (["two", "three"].includes(profile?.kycLevel || "one")) return "approved";
      // return null;
    }

    const level2 = data?.find((d: any) => d?.kycLevel === "two");
    if (!!level2 && !!level2?.status) return level2?.status;
    if (user && !isEmptyString(user?.kycLevel) && user?.kycLevel === "one") return null;
    if (user && !isEmptyString(user?.kycLevel) && (user?.kycLevel === "two" || user?.kycLevel === "three")) return "approved";
    // if (!level2) return null;
    return level2?.status ?? null;
  }, [user, data, kycConfig, profile, kycStatus]);

  // console.log("Level2 Verification", Level2Status);

  const stepsCompleted = useMemo(
    () => [true, Level2Status === "approved", Level3Status === "approved"],
    [Level2Status, Level3Status]
  );

  // const startSumsubKyc = async () => {
  //   const { email, phone } = profile ?? {};
  //   if (!email || !phone) return;
  //   const access_token = await getSumsubNewAccessToken();

  //   launchSumsubWebSdk({
  //     accessToken: access_token,
  //     applicantEmail: email,
  //     applicantPhone: phone,
  //     theme: "light",
  //   });
  // };

  return (
    <KycContext.Provider value={{ refetchStatus: refetch }}>
      <PageMotion subpage key="AccountVerification" overflowY="hidden">
        <MainLayoutContainer
          // px="140px"
          px={{ base: "20px", "2sm": "80px", "4sm": "40px", md: "40px", xl: "0px" }}
          py="10px"
        >
          <Box>
            <Button
              size="sm"
              minW="fit-content"
              maxW="fit-content"
              variant="link"
              leftIcon={<Icon type="circleLeftArrow" color={stroke} />}
              onClick={goBack}
            >
              Back
            </Button>

            <HStack justifyContent="space-between" mt="10px">
              <Heading as="h5" fontSize="xl">
                Account Verification
              </Heading>
            </HStack>
          </Box>

          <Box>
            {/* <VerificationStat onLevelChange={(level) => setLevel(level)} /> */}
            {Level3Status !== "approved" && (
              <Text mt="30px" fontWeight="500">
                Complete your Level {when(Level2Status !== "approved", 2, 3)} Account Verification
              </Text>
            )}

            <Box mt="30px" />

            <Steps variant="verification" labels={["Level 1", "Level 2", "Level 3"]} stepsCompleted={stepsCompleted}>
              <LevelOne
                status={"approved"}
                nextLevelStatus={Level2Status}
                {...({ buttonProps: { next: { disabled: Level2Status !== "approved" } } } as any)}
              />
              <LevelTwo
                status={Level2Status}
                nextLevelStatus={Level3Status}
                refetchStatus={refetch}
                {...({ buttonProps: { next: { disabled: Level3Status !== "approved" } } } as any)}
              />
              <LevelThree
                status={Level3Status}
                nextLevelStatus={"approved"}
                {...({ buttonProps: { next: { disabled: Level3Status !== "approved" } } } as any)}
              />
            </Steps>

            {/* 
            //////////////////////////// DO NOT DELETE THIS FOR FUTURE REFERENCE //////////////////////////////////
            <AnimatePresence exitBeforeEnter initial={false}>
            <VStack>
              <Heading as="h6" fontSize="lg">
                Limit
              </Heading>

              <Text mt="24px !important" fontWeight="500">
                Account Limit
              </Text>

              <motion.div
                key={`level-limit-${level}`}
                initial={{ opacity: 0, scale: 0.88, rotate: "-10deg", y: 20 }}
                animate={{ opacity: 1, scale: 1, rotate: "0deg", y: 0 }}
                exit={{ opacity: 0, scale: 0.88, rotate: "-10deg", y: -20 }}
              >
                <Text fontWeight="700">{levelLimit}</Text>
              </motion.div>

              {level === 1 && (
                <Button mt="78px !important" disabled>
                  Verified
                </Button>
              )}

              {level === 2 && (
                <Button
                  mt="78px !important"
                  onClick={handleClick}
                  disabled={getLevel2Status() === "pending" || getLevel2Status() === "approved" ? true : false}
                >
                  {getLevel2Status() === "pending" || getLevel2Status() === "approved" || getLevel2Status() === "denied"
                    ? getLevel2Status()
                    : "Verify"}
                </Button>
              )}

              {level === 3 && (
                <Button
                  mt="78px !important"
                  onClick={handleClick}
                  disabled={
                    getLevel3tatus() === "pending" || getLevel3tatus() === "approved" ? true : false || user?.kycLevel! === "one"
                  }
                >
                  {getLevel3tatus() === "pending" || getLevel3tatus() === "approved" || getLevel3tatus() === "denied"
                    ? getLevel2Status()
                    : "Verify"}
                </Button>
              )}
            </VStack>
          </AnimatePresence> */}
          </Box>
        </MainLayoutContainer>
      </PageMotion>
    </KycContext.Provider>
  );
}

function LevelOne(props: any) {
  const { status, nextLevelStatus } = props;
  const { onNext } = useModalView();

  return (
    <Box my="55px" p="10px">
      <HStack>
        <Icon type="verificationCompleted" />
        <Text fontFamily="var(--bitmama-fonts-heading)">Status: Completed</Text>
      </HStack>

      <VStack mt="130px !important">
        <VerifyButton status={status} onNext={onNext} level={1} nextLevelStatus={nextLevelStatus} />
        {/* <Button variant="transparent" fontWeight="500">
          Do this later
        </Button> */}
      </VStack>
    </Box>
  );
}

function LevelTwo(props: any) {
  const { status, nextLevelStatus, refetchStatus } = props;
  const { onNext, onPrev: onClose } = useModalView();

  const { isOpen, onClose: closeModal, onOpen } = useDisclosure();

  const dispatch = useDispatch();
  const { profile } = useSelector(selectUser);
  const [, setSnsInstance] = useState<SnsWebSdk | null>(null);

  // const { appfigs } = useAppConfig();
  // const { kycConfig } = appfigs ?? {};

  console.log("Level 2 Status", status, nextLevelStatus);

  const info = useMemo(() => statusInfo(status), [status]);

  // const is_verified = useMemo(() => {
  //   sns_instance.
  // }, [])

  const startSumsubKyc = async () => {
    const { email, phone } = profile ?? {};
    if (!email || !phone) return;
    const access_token = await getSumsubNewAccessToken("two");
    console.log("Got here", { email, phone, access_token });
    onOpen();

    const sdk = launchSumsubWebSdk(
      {
        accessToken: access_token,
        applicantEmail: email,
        applicantPhone: phone,
        theme: "light",
        // container: '',
      },
      {
        async onIdStepComplete() {
          dispatch(getProfile);
        },
      }
    );

    setSnsInstance(sdk);
  };

  return (
    <Steps hideIndicator>
      <Stack my="55px" p="10px" gap="20px">
        <HStack gridGap="26px">
          <Info title="Requirements" description="Valid ID" />
          <Info title="Account Limit" description="$20,000 per day" />
        </HStack>

        <HStack>
          <Icon type={info?.iconName ?? "failed"} />
          <Text fontFamily="var(--bitmama-fonts-heading)">Status: {capitalize(info?.label ?? "nothing")}</Text>
        </HStack>

        <VStack mt="130px !important">
          {/* <Next /> */}

          {/* <ConditionalRender shouldRender={!!kycConfig?.method && ["manual"].includes(kycConfig?.method)}>
            <VerifyButton status={status} onNext={onNext} level={2} nextLevelStatus={nextLevelStatus} />
          </ConditionalRender> */}

          <VerifyButton
            status={status}
            onNext={onNext}
            level={2}
            nextLevelStatus={nextLevelStatus}
            startSumsubKycVerification={startSumsubKyc}
          />

          {/* <ConditionalRender shouldRender={!!kycConfig?.method && ["sumsub"].includes(kycConfig?.method)}>
            <SumsubKycButton startKyc={startSumsubKyc} />
          </ConditionalRender> */}

          {/* <Button variant="transparent" fontWeight="500">
            Do this later
          </Button> */}
        </VStack>

        <SumsubKycModal isOpen={isOpen} onClose={closeModal} />
      </Stack>

      <Box my="36px">
        <HStack gridGap="26px">
          <Info title="Requirements" description="Valid ID" />
          <Info title="Account Limit" description="$20,000 per day" />
        </HStack>

        <Level2VerificationForm onClose={onClose} refetchStatus={refetchStatus} />
      </Box>
    </Steps>
  );
}

function LevelThree(props: any) {
  const { status, nextLevelStatus } = props;
  const { onNext } = useModalView();

  const { isOpen, onClose: closeModal, onOpen } = useDisclosure();
  console.log("level 3 status", status, nextLevelStatus);

  const info = useMemo(() => statusInfo(status), [status]);

  const dispatch = useDispatch();
  const { profile } = useSelector(selectUser);

  const startSumsubKyc = async () => {
    const { email, phone } = profile ?? {};
    if (!email || !phone) return;
    const access_token = await getSumsubNewAccessToken("three");
    // console.log("Got here", { email, phone, access_token });
    onOpen();

    launchSumsubWebSdk(
      {
        accessToken: access_token,
        applicantEmail: email,
        applicantPhone: phone,
        theme: "light",
        // container: '',
      },
      {
        async onIdStepComplete() {
          dispatch(getProfile);
        },
      }
    );
  };

  return (
    <Steps hideIndicator>
      <Stack my="55px" p="10px" gap="20px">
        <HStack gridGap="26px">
          <Info title="Requirements" description="Bank & Address Info" />
          <Info title="Account Limit" description="Unlimited" />
        </HStack>

        <HStack>
          <Icon type={info.iconName} />
          <Text fontFamily="var(--bitmama-fonts-heading)">Status: {capitalize(info.label)}</Text>
        </HStack>

        <VStack mt="130px !important">
          {/* <Next /> */}
          {/* <ConditionalRender shouldRender={!!kycConfig?.method && ["manual"].includes(kycConfig?.method)}>
            <VerifyButton status={status} onNext={onNext} level={3} nextLevelStatus={nextLevelStatus} />
          </ConditionalRender> */}

          <VerifyButton
            status={status}
            onNext={onNext}
            level={3}
            nextLevelStatus={nextLevelStatus}
            startSumsubKycVerification={startSumsubKyc}
          />

          {/* <ConditionalRender shouldRender={!!kycConfig?.method && ["sumsub"].includes(kycConfig?.method)}>
            <SumsubKycButton
              startKyc={startSumsubKyc}
              status={status}
              onNext={onNext}
              level={3}
              nextLevelStatus={nextLevelStatus}
            />
          </ConditionalRender> */}

          <SumsubKycModal isOpen={isOpen} onClose={closeModal} />
        </VStack>
      </Stack>

      <Box my="36px">
        <HStack gridGap="26px">
          <Info title="Requirements" description="Bank & Address Info" />
          <Info title="Account Limit" description="Unlimited" />
        </HStack>

        <Level3VerificationForm />
      </Box>
    </Steps>
  );
}

// eslint-disable-next-line
// @ts-ignore
// function Next(props: any) {
//   const { onNext } = useModalView();

//   return <button onClick={onNext}>next</button>;
// }

function Info(props: any) {
  const { title, description } = props;

  const bg = useColorModeValue("grey.150", "dark.cardBg");

  return (
    <Box p="10px" borderRadius="6px" bg={bg}>
      <Text fontSize="sm" fontWeight="500">
        {title}
      </Text>
      <Text fontSize="sm" fontWeight="700">
        {description}
      </Text>
    </Box>
  );
}

interface VerifyButtonProps extends ButtonProps {
  status: string;
  nextLevelStatus: string;
  onNext: () => void;
  level: number;
  startSumsubKycVerification?: () => Promise<void>;
}

function VerifyButton(props: VerifyButtonProps) {
  const { status, onNext, level, nextLevelStatus, startSumsubKycVerification } = props;

  const { appfigs } = useAppConfig();
  const { kycConfig } = appfigs ?? {};

  const { onNext: onVerify } = useModalView();

  const { profile } = useSelector(selectUser);
  const canNotVerify = useMemo(() => ["canada", "united kingdom"].includes(toLower(profile?.country)), [profile]);

  const label = useMemo(() => {
    // if (kycConfig?.method === "sumsub") {
    //   if (level === 2 && profile?.kycLevel === "two") return `Proceed to level ${level + 1}`;
    //   if (level === 3 && profile?.kycLevel === "three") return `Verified`;
    // }

    if (status === "pending") return "Pending";
    if (level === 3 && status === "approved") return "Verified";
    if (status === "approved" && nextLevelStatus === "approved") return `Verified`;
    if (status === "approved" && nextLevelStatus !== "approved") return `Proceed to level ${level + 1}`;
    return `Verify level ${level}`;
  }, [status, level, nextLevelStatus]);

  const isDisabled = useMemo(() => {
    console.log("Status", status, nextLevelStatus);

    // if (kycConfig?.method === "sumsub") {
    //   if (level === 2 && profile?.kycLevel === "two") return false;
    //   if (level === 3 && profile?.kycLevel === "three") return true;
    // }

    if (level === 3 && status === "approved") return true;
    if (status === "approved" && nextLevelStatus !== "approved") return false;
    if (status === "approved" && nextLevelStatus === "approved") return true;
    if (status === "pending") return true;
    if (status === "declined") return false;
    return false;
  }, [status, level, nextLevelStatus]);

  const handleClick = async () => {
    // if (kycConfig?.method === "sumsub") {

    //   if (level === 1) return onNext();
    //   if (level === 2 && profile?.kycLevel === "two") return onNext();
    //   if (level === 3 && profile?.kycLevel === "three") return;

    //   return await startSumsubKycVerification?.();
    // }

    if (status === "approved") {
      onNext();
      return;
    }

    if (status !== "approved") {
      if (kycConfig?.method === "sumsub") return await startSumsubKycVerification?.();
      return onVerify();
    }
  };

  return (
    // <Tooltip label="Verification is currently unavailable for you country">
    <Button
      fontFamily="var(--bitmama-fonts-heading)"
      onClick={handleClick}
      isDisabled={isDisabled || canNotVerify}
      disabled={isDisabled || canNotVerify}
      minW={{ base: "100%", smx: "330px", "4sm": "400px" }}
    >
      {label}
    </Button>
    // </Tooltip>
  );
}

const statusInfo = (status: string) => {
  const map: Record<string, { label: string; iconName: IconNames }> = {
    default: { label: "Unverified", iconName: "verificationPending" },
    approved: { label: "Completed", iconName: "verificationCompleted" },
    pending: { label: "Pending", iconName: "verificationPending" },
    denied: { label: "Failed", iconName: "verificationFailed" },
    expired: { label: "Expired", iconName: "verificationFailed" },
    withdrawn: { label: "Withdrawn", iconName: "verificationFailed" },
  };

  return map[status ?? "default"];
};

// interface ISumsubKycButton {
//   startKyc: () => Promise<void>;
//   isDisabled?: boolean;
//   isLoading?: boolean;
// }

// function SumsubKycButton(props: ISumsubKycButton) {
//   const { startKyc, isDisabled, isLoading } = props;

//   return (
//     <Button
//       fontFamily="var(--bitmama-fonts-heading)"
//       isDisabled={isDisabled || isLoading}
//       isLoading={isLoading}
//       minW={{ base: "100%", smx: "330px", "4sm": "400px" }}
//       onClick={startKyc}
//     >
//       Start KYC Verification
//     </Button>
//   );
// }
