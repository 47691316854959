import { useGetCardWishlistEligibilityQuery, useGetCardWishlistStatusQuery, useGetLatestHistoryQuery } from "apis";
import configs from "config";
import { useAuth, useDisclosures, useEventListener, useLocation, usePageVisibility, usePartialState, useSelector } from "hooks";
import { isEqual, orderBy } from "lodash";
import { createContext, useCallback, useContext, useEffect, useMemo, useRef } from "react";
import {
  ConfirmationModal,
  FiatFundModal,
  FiatWithdrawModal,
  GeneralRatingModal,
  PreclaimGreenBox,
  PyusdFeatureUpdate,
  SignupModal,
} from "ui";
// import { ChristmasUpdate } from "ui/Common/Modals/Christmas";
import { lessThanSomeMinutesAgo } from "utils";

import ls from "utils/secureStorage";
import { useAppConfig } from "./appconfig.context";
import { ConditionalRender } from "components";
import { ValentineUpdate } from "ui/Common/Modals/Valentine";
import { ValentineWishlistStatus } from "ui/Common/Modals/ValWishlistStatus";
import { GamificationUpdate } from "ui/Common/Modals/Gamification";
import { NewEarnBadgeUpdate } from "ui/Common/Modals/NewEarnBadge";
import { selectAuth } from "store/slices";
import { differenceInHours } from "date-fns";
import { Text, VStack } from "@chakra-ui/react";

type ModalTypes =
  | "fiat:fund"
  | "fiat:withdraw"
  | "signup"
  | "preclaim"
  | "pyusd_feature"
  | "christmas_updates"
  | "gamification_updates"
  | "new_earned_badge_update"
  | "valentine_wishlist_status"
  | "survey_rating"
  | "request_signin";

interface IModalContext extends Omit<ReturnType<typeof useDisclosures<ModalTypes>>, "open"> {
  open: (key: ModalTypes, state?: Partial<IState>) => () => void;
}

interface IState {
  coin: string;
}

export const ModalContext = createContext<IModalContext | null>(null);

export default function ModalsProvider(props: any) {
  const location = useLocation();

  const is_app_visible = usePageVisibility();

  const { logout } = useAuth();
  const { profile, auth, hasWonButNotSeen, gameCheck } = useAppConfig();
  const { isOpen, close, open, onOpen, onClose, modal, _is_open } = useDisclosures<ModalTypes>();
  const [state, set] = usePartialState<Record<ModalTypes, Partial<IState>>>();

  // const [last_achievement, setLastAchievement] = useState<{ title: string; message: string } | null>();

  // const { last_achievement } = useGamificationUpdateCheck({ onOpen });

  // const last_achievement = {};

  const handleOpen = (key: ModalTypes, state?: Partial<IState>) => () => {
    open(key)();
    set({ [key]: state });
  };

  useEventListener("request_signin", () => {
    open("request_signin")();
  });

  // useEffect(() => {
  //   if (!isOpen("pyusd_feature")) onOpen("pyusd_feature");
  //   //eslint-disable-next-line
  // }, []);

  // useEffect(() => {
  //   if (!isOpen("new_earned_badge_update")) onOpen("new_earned_badge_update");
  //   //eslint-disable-next-line
  // }, []);

  const should_show_survey = useCallback(() => {
    if (!!_is_open && !auth?.isSignedIn) return false;

    type SurveyRatingLS = { last_closed: number };
    const sr = (ls.get("survey_rating") ?? {}) as SurveyRatingLS;

    console.log("Survey Rating", sr);

    if (!sr?.last_closed) return true;
    if (differenceInHours(sr?.last_closed, Date.now()) >= 2) return true;

    return false;
  }, [_is_open, auth]);

  useEffect(() => {
    if (!isOpen("valentine_wishlist_status") && hasWonButNotSeen) onOpen("valentine_wishlist_status");

    if (!isOpen("survey_rating") && !!should_show_survey()) onOpen("survey_rating");

    //eslint-disable-next-line
  }, [hasWonButNotSeen, should_show_survey]);

  useEffect(() => {
    const currentPath = location.location.pathname;

    const isEligiblePath = ["/greenbox", "/home", "/account"].some((path) => currentPath.startsWith(path));
    if (isEligiblePath) {
      let greenbox = ls.get(configs.GREENBOX_CODE_CACHE);

      if (!!greenbox) {
        if (typeof greenbox === "string")
          greenbox = JSON.parse(greenbox) as { status: "preclaimed"; code: string; claimLock?: string; date?: string };
        console.log("A pending greenbox preclaim, ID --> ", greenbox, greenbox.code);
        // window.location.replace(`/gbx/${greenbox.code}`);
        let stillActive = lessThanSomeMinutesAgo(new Date(greenbox.date), 10) && greenbox.status === "preclaimed";
        if (stillActive) open("preclaim")();
      }
    }

    //eslint-disable-next-line
  }, [location.location.pathname]);

  console.log("Is app visible", is_app_visible);

  return (
    <ModalContext.Provider value={{ isOpen, close, open: handleOpen, onOpen, onClose, modal, _is_open }}>
      {props?.children}

      {/* AUTH MODALS */}
      <SignupModal isOpen={isOpen("signup")} onClose={close("signup")} />

      <PreclaimGreenBox isOpen={isOpen("preclaim")} onClose={close("preclaim")} />

      <ConditionalRender shouldRender={!!auth?.isSignedIn}>
        {/* FIAT MODALS */}
        <FiatFundModal isOpen={isOpen("fiat:fund")} onClose={close("fiat:fund")} {...state["fiat:fund"]} />
        <FiatWithdrawModal isOpen={isOpen("fiat:withdraw")} onClose={close("fiat:withdraw")} {...state["fiat:withdraw"]} />

        <PyusdFeatureUpdate
          isOpen={isOpen("pyusd_feature")}
          onClose={() => {
            close("pyusd_feature")();
            ls.set(configs.PYUSD_REMIND, "remind");
          }}
        />
        {/* <ChristmasUpdate
          isOpen={isOpen("christmas_updates")}
          onClose={() => {
            close("christmas_updates")();
            ls.set(configs.XMAS_REMIND, "remind");
          }}
        /> */}
        <ValentineUpdate
          isOpen={isOpen("christmas_updates")}
          // isOpen={true}
          onClose={() => {
            close("christmas_updates")();
            ls.set(configs.XMAS_REMIND, "remind");
          }}
        />
        <ValentineWishlistStatus
          isOpen={isOpen("valentine_wishlist_status")}
          // isOpen={true}
          onClose={() => {
            close("valentine_wishlist_status")();
            // ls.set("W_REMIND", "remind");
          }}
        />
        <GamificationUpdate
          isOpen={isOpen("gamification_updates")}
          // isOpen={true}
          onClose={() => {
            close("gamification_updates")();
            ls.set(configs.GAME_REMIND(profile?.userId ?? "abc"), "remind");
          }}
        />
        <NewEarnBadgeUpdate
          // isOpen={isOpen("new_earned_badge_update")}
          isOpen={gameCheck.is_gc_open}
          // isOpen={true}
          onClose={() => {
            gameCheck.on_gc_close();
            // close("new_earned_badge_update")();
            // ls.set(configs.GAME_REMIND, "remind");
          }}
          {...(gameCheck?.last_achievement ?? {})}
        />

        <GeneralRatingModal
          isOpen={isOpen("survey_rating")}
          // isOpen={true}
          onClose={() => {
            onClose("survey_rating");
            ls.set("survey_rating", { last_closed: Date.now() });
          }}
        />

        <ConfirmationModal
          // icon="infoRounded"
          isOpen={isOpen("request_signin")}
          onClose={close("request_signin")}
          title="Re-authentication Required"
          onConfirm={() => logout()}
          labels={["Logout & Sign In", "Cancel"]}
        >
          <VStack my="40px">
            <Text textAlign="center">Your account details have changed. Please sign in again to continue.</Text>
          </VStack>
        </ConfirmationModal>
      </ConditionalRender>
    </ModalContext.Provider>
  );
}

export function useModals() {
  const context = useContext(ModalContext);
  if (!context) throw new Error("useModal should be used within the ModalsProvider");
  return context;
}

export function useWishlistCheck(skipChecks = true) {
  const { data: status } = useGetCardWishlistStatusQuery("false", { skip: skipChecks });
  const { data: fundwish } = useGetCardWishlistEligibilityQuery("fund", { skip: skipChecks });

  console.log("Eligibility", fundwish);

  const isEligibleRaw = useMemo(() => fundwish?.eligible || false, [fundwish]);
  const hasApplied = useMemo(() => fundwish?.applied || false, [fundwish]);
  const hasWonButNotSeen = useMemo(
    () => !!status?._id && ["fufilled", "fulfilled"].includes(status?.status ?? "none") && !status?.seen,
    [status]
  );
  const isEligible = useMemo(() => !!isEligibleRaw && !hasWonButNotSeen, [isEligibleRaw, hasWonButNotSeen]);

  console.log("Wishlist Status", { status, hasWonAndNotSeen: hasWonButNotSeen });

  type DontShowType = { cycle: number };

  const hasNewCycle = useMemo(() => {
    const cached = ls.get(configs.XMAS_REMIND);
    let dont_show = ls.get(configs.XMAS_DONT_SHOW) as DontShowType;
    dont_show = typeof dont_show === "string" ? { cycle: -1 } : dont_show;
    const curr: DontShowType = { cycle: fundwish?.cycle ?? 0 };

    console.log("[useWishlistCheck]Eligibility", { fundwish, curr, dont_show, cached });
    // Whenever there's a new cycle, the `applied` field will be updated to false, since that's the case
    // We can use that to check if there's a new cycle.
    if (!hasApplied) {
      ls.remove(configs.XMAS_REMIND);
      const isNewCycle = !!dont_show && !isEqual(dont_show, curr);
      return isNewCycle;
    }

    return !!cached;

    //eslint-disable-next-line
  }, [hasApplied, fundwish?.cycle]);

  console.log("Eligibility Check", { isEligible, hasApplied, hasNewCycle, hasWonButNotSeenReverse: !hasWonButNotSeen });

  return { isEligible, hasApplied, hasNewCycle, hasWonButNotSeen };
}

interface IUseGamificationUpdateCheck {
  onOpen: (modalName: string) => void;
}
export function useGamificationUpdateCheck(args: IUseGamificationUpdateCheck) {
  const { onOpen } = args;

  const { payload } = useSelector(selectAuth);
  const prev_data_id = useRef<string>("none");

  console.log("Auth payload", payload);

  // const { data, refetch } = useGetHistoryQuery("all", {
  //   pollingInterval: 5000,
  //   skip: !payload?.isSignedIn,
  // });

  const { data: latest, refetch } = useGetLatestHistoryQuery("", {
    // pollingInterval: 5000,
    skip: !payload?.isSignedIn,
  });
  console.log("Latest badges", orderBy(latest?.data, ["updatedAt"], ["desc"]));

  const last_achievement = useMemo(() => orderBy(latest?.data, ["updatedAt"], ["desc"])[0], [latest]);

  const GAME_AWARD_KEY = "last_game_award_id";
  useEffect(() => {
    const last_propagated_id = ls.get(GAME_AWARD_KEY);
    console.log("History from modal.context[before]", { last_propagated_id, last_achievement });

    if (!!last_achievement && prev_data_id.current !== last_achievement?._id && last_propagated_id !== last_achievement?._id) {
      console.log("History from modal.context", last_achievement);
      prev_data_id.current = last_achievement?._id;
      ls.set(GAME_AWARD_KEY, last_achievement?._id);
      onOpen("new_earned_badge_update");
    }

    const timeout_id = setTimeout(() => {
      if (prev_data_id.current !== "none") prev_data_id.current = "none";
    }, 8000);

    return () => clearTimeout(timeout_id);
  }, [prev_data_id, last_achievement, onOpen]);

  // const {hasWonButNotSeen} = useWishlistCheck()

  useEventListener("refetch_badge_earned", (data: any) => {
    console.log("Earn a new badge", data);
    refetch();
    // setLastAchievement(data?.metadata);

    // window.alert("Earn a new badge");
  });

  return { last_achievement };
}
