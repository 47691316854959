import configs from "config";
import isFiat from "./isFiat";

type SupportCurrency = "ngn" | "ghs" | "kes" | "usd" | "xof" | "cdf" | "xaf";

const currencyMap: Record<SupportCurrency, string> = {
  ngn: "en-NG",
  ghs: "en-GH",
  kes: "en-KE",
  usd: "en-US",

  // TODO: update the locales for each currency below
  xof: "ff-SN",
  xaf: "ff-CM",
  cdf: "ff-CD",
};

const formatter = (currency: SupportCurrency) => {
  const isFranc = configs.francoCoins.includes(currency);

  if (isFranc) {
    return {
      format(amount: number) {
        return String(currency + " ")
          .toUpperCase()
          .concat(numberWithCommas(MathRound(amount, 2).toString()));
      },
    };
  }

  return new Intl.NumberFormat(currencyMap[currency], {
    style: "currency",
    currency: String(currency).toUpperCase(),

    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
  });
};

const currencyFormat = (currency: SupportCurrency) => formatter(currency);

export default currencyFormat;

const toPrecision = (number: number | string, precision: number = 2) => {
  const num = Number(number);
  return Number(num.toFixed(precision));
};

// MathRound(3.256, 2) => 3.25 gives instead of 3.26
export const MathRound = (num: number, nrdecimals: number) => {
  let n = Number(Number(num).toFixed(nrdecimals));
  return (n > num ? toPrecision(n - 1 / Math.pow(10, nrdecimals), nrdecimals) : n).toFixed(nrdecimals) as unknown as number;
};

export const numberWithCommas = (x: string) => {
  const parts = x.toString().split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return parts.join(".");
};

export const formatValuePerUnit = (value: number | string, unit?: string) => {
  if (!unit) return value;
  if (isFiat(unit)) {
    return numberWithCommas(MathRound(Number(value), 2).toFixed(2));
  }
  return MathRound(Number(value), 2);
};