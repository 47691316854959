import { Box, Button, HStack, Heading } from "@chakra-ui/react";
import { Group, Icon, MainLayoutContainer, PageMotion, Title, TitleBar, Topbar } from "components";
import { useCallback, useMemo, useRef, useState } from "react";
import { swipePower, SWIPE_CONFIDENCE_THRESHOLD, wrap, when, toCoinLocale, isFiat } from "utils";
import { AnimatePresence, Variants, motion } from "framer-motion";
import { AssetWallet } from "components/Wallet/AssetWallet";
import { useColor, useDefaultStyle, useDisclosures, usePartialState, useRedebounce } from "hooks";
import { navigate } from "@reach/router";
import { useModals } from "contexts";
import { toLower } from "lodash";
import { useAppConfig } from "contexts/appconfig.context";
// import { currencyMap } from "hooks/useCurrency";
// import { selectUser } from "store/slices";
import { FeatureUnavailableModal } from "ui";
import ls from "utils/secureStorage";
import { normalizeTestCoin } from "utils/crypto";

export function Testground() {
  const [coin, setCoin] = useState("btc");

  return (
    <PageMotion key="testground">
      <Topbar pageTitle="Test ground v.01" />
      <MainLayoutContainer>
        <Heading fontSize="lg">Total Assets in (USDT)</Heading>

        <TotalAssetsWalletCarousel
          coins={["btc", "usdt", "teth"]}
          tokens_and_balance={[]}
          coin={coin}
          setCoin={setCoin}
          balance={0.00034}
        />
      </MainLayoutContainer>
    </PageMotion>
  );
}

const variants: Variants = {
  enter: (direction: number) => {
    return {
      x: direction > 0 ? 1000 : -1000,
      opacity: 0,
    };
  },
  //   center: {
  //     zIndex: 1,
  //     x: 0,
  //     opacity: 1,
  //   },
  exit: (direction: number) => {
    return {
      zIndex: 0,
      x: direction < 0 ? 1000 : -1000,
      opacity: 0,
    };
  },

  center: {
    x: "0rem",
    opacity: 1,
    scale: 1,
    zIndex: "5",
    filter: "brightness(100%)",
    boxShadow: "0px 0px 19px 0px rgba(0,0,0,0.1)",
    borderRadius: "20px",
    transition: {
      type: "spring",
      duration: 1,
    },
  },
  left: {
    x: "-6rem",
    opacity: 1,
    filter: "brightness(40%)",
    scale: 0.7,
    zIndex: "4",
    transition: {
      type: "spring",
      duration: 1,
    },
    // pointerEvents: "none",
    // cursor: "default",
  },
  right: {
    x: "6rem",
    opacity: 1,
    filter: "brightness(40%)",
    scale: 0.7,
    zIndex: "3",
    transition: {
      type: "spring",
      duration: 1,
    },
    // pointerEvents: "none",
    // cursor: "default",
  },
  right_hidden: {
    x: "8rem",
    scale: 0,
    opacity: 0,
    pointerEvents: "none",
    cursor: "default",
  },
  left_hidden: {
    x: "-8rem",
    scale: 0,
    opacity: 0,
    pointerEvents: "none",
    cursor: "default",
  },

  visible: {
    opacity: 1,
    // scale: 1,
    y: 0,
    transformOrigin: "center",
    // rotateX: "0deg",
    transition: {
      type: "spring",
      bounce: 0.46,
    },
  },
  hidden: {
    opacity: 0,
    y: -4,
    transition: {
      type: "spring",
      bounce: 0.1,
    },
  },
  reveal: {
    opacity: 0,
    y: 10,
    transition: {
      type: "spring",
      bounce: 0.1,
    },
  },
};

const MotionBox = motion(Box);

interface TotalAssetsWalletCarouselProps {
  coins: string[];
  coin: string;
  setCoin: (coin: string) => void;
  balance?: number;
  isLoading?: boolean;
  tokens_and_balance: { token: string; balance: number }[];
  canBeHidden?: boolean;
}

type ModalTypes = "featureUnavailable";
export function TotalAssetsWalletCarousel(props: TotalAssetsWalletCarouselProps) {
  const { coins, coin, setCoin, tokens_and_balance, canBeHidden = false, isLoading = false } = props;
  //   const coins = ["usdt", "btc", "eth"];

  const color = useColor();
  const { open } = useModals();
  //   const [coin, setCoin] = useState("usdt");
  const { shadow, borderColor } = useDefaultStyle();
  const [[page, direction], setPage] = useState([1, 0]);
  const [is_animation_running, setIsRunning] = useState(false);
  const { isOpen, open: dopen, close } = useDisclosures<ModalTypes>();

  type HiddenType = { isHidden: boolean };

  const isHidden = useMemo(() => {
    const result = ls.get("TOTAL_VALUE");
    if (!!result) return canBeHidden && (JSON.parse(result) as HiddenType).isHidden;
    return canBeHidden;
  }, [canBeHidden]);

  const [state, set] = usePartialState<HiddenType>({ isHidden }, [isHidden]);

  const { appfigs } = useAppConfig();
  // const { profile } = useSelector(selectUser);
  const { depositConfig, withdrawalConfig } = appfigs ?? {};

  const isFundingDisabled = useCallback(
    (coin?: string) => {
      if (isFiat(coin!)) {
        if (depositConfig?.disableFiatDeposit) {
          return true;
        }

        if (coin) {
          return !depositConfig?.supportedCurrencies?.[coin];
        }
      } else {
        if (depositConfig?.disableCryptoDeposit) {
          return true;
        }

        const _coin = normalizeTestCoin(coin! ?? "");
        if (_coin) {
          return !depositConfig?.supportedCoins?.[_coin];
        }
      }

      return false;
    },
    [depositConfig]
  );

  // const isDepositFiatDisabled = useMemo(() => !!depositConfig?.disableFiatDeposit ?? false, [depositConfig]);
  const isWithdrawFiatDisabled = useMemo(() => !!withdrawalConfig?.disableFiatWithdrawal ?? false, [withdrawalConfig]);

  // const canFundWallet = useCallback(
  //   (coin: string) => {
  //     const country_name = toLower(profile?.country ?? "nigeria");
  //     const map = currencyMap[country_name] ?? currencyMap["default"];
  //     return map.currency === coin && !isDepositFiatDisabled;
  //   },
  //   [profile, isDepositFiatDisabled]
  // );

  // const canWithdrawWallet = useCallback(
  //   (coin: string) => {
  //     const country_name = toLower(profile?.country ?? "nigeria");
  //     const opt = currencyMap[country_name] ?? currencyMap["default"];
  //     return opt?.currency === coin && opt?.enabled && !isWithdrawFiatDisabled;
  //   },
  //   [profile, isWithdrawFiatDisabled]
  // );

  //   const totalPages = useMemo(() => Math.ceil(cards.length / 4), [cards]);
  const totalPages = useMemo(() => coins.length, [coins]);
  const debounce = useRedebounce();
  //   const totalPages = 3;

  const cardIndex = wrap(0, totalPages, page);

  const getBalance = useCallback(
    (coin: string) => {
      const token = tokens_and_balance.find(({ token }) => toLower(token) === toLower(coin) || toLower(coin).includes(token));
      return token?.balance ?? 0;
    },
    [tokens_and_balance]
  );

  const paginate = (newDirection: number) => {
    if (is_animation_running) return;

    const newPage = page + newDirection;
    const index = wrap(0, totalPages, newPage);

    setPage([newPage, newDirection]);
    setCoin(coins[index]);

    console.log("Paginate", { index, newPage, coins });
  };

  const current_animation = useCallback(
    (index: number) => {
      if (cardIndex < index) return "right";
      else if (cardIndex > index) return "left";
      return "center";
    },
    [cardIndex]
  );

  const dir_flowing_right = useMemo(() => when(direction < 1, false, true), [direction]);
  const w = (index: number) => when(cardIndex === index, "18px", "10px");
  const bg = (index: number) =>
    when<any>(
      cardIndex === index,
      color("linear-gradient(45deg, #072420, #31B7A982)", "linear-gradient(45deg, #31B7A982, var(--bitmama-colors-secondary-400))"),
      color("#CFDAD9", "whiteAlpha.300")
    );

  const get_initials = useCallback(
    (index: number) => {
      const animation = current_animation(index);
      const map: Record<string, { initial: string; exit: string }> = {
        center: { initial: when(dir_flowing_right, "right", "left"), exit: "none" },
        left: { initial: when(dir_flowing_right, "center", "left_hidden"), exit: "left_hidden" },
        right: { initial: when(dir_flowing_right, "right_hidden", "center"), exit: "right_hidden" },
      };

      const result = map[animation];

      // console.log(`Initials - ${animation}`, result);

      return result;
    },
    [current_animation, dir_flowing_right]
  );

  const tooltipRef = useRef<any>();

  const toggleBalanceVisibility = () => {
    set({ isHidden: !state?.isHidden });
    ls.set("TOTAL_VALUE", JSON.stringify({ isHidden: !state?.isHidden }));
  };

  return (
    <Group my="0px !important">
      <TitleBar w="100%" maxW={{ base: "280px", smx: "290px", sm: "100%", "1sm": "100%", md: "540px" }}>
        <Title isLoading={false} fontSize="18px" pos="relative">
          <HStack pos="relative" ref={tooltipRef}>
            <Box fontWeight="inherit">Total Asset in</Box>
            {/* <Box fontWeight="inherit">(</Box> */}
            <Box fontWeight="inherit" ml="0.4rem !important">
              <AnimatePresence exitBeforeEnter initial={false}>
                <MotionBox
                  key={`text-${coin}`}
                  initial="reveal"
                  animate="visible"
                  exit="hidden"
                  variants={variants}
                  transition={{
                    x: { type: "spring", stiffness: 300, damping: 30 },
                    opacity: { duration: 0.2 },
                  }}
                >
                  {toCoinLocale(coin)}
                </MotionBox>
              </AnimatePresence>
            </Box>

            {/* <Box pos="relative">
              <Tooltip label={"Now you see me 😒"} placement="right" hasArrow portalProps={{ containerRef: tooltipRef }}>
                <Icon
                  type="infoRounded"
                  color={color("primary.800", "secondary.500")}
                  _hover={{ color: color("secondary.500", "primary.600") }}
                />
              </Tooltip>
            </Box> */}
          </HStack>
        </Title>
      </TitleBar>

      <MotionBox display="block" placeContent="center" mt="8px !important">
        <MotionBox pos="relative" display="flex" w="580px" h="240px" py="20px" justifyContent="center" alignItems="center">
          <AnimatePresence initial={false}>
            {(coins ?? []).map((coin, i) => (
              <MotionBox
                key={`wallet-${i}`}
                pos="absolute"
                custom={direction}
                variants={variants}
                //   initial="enter"
                initial={get_initials(i).initial}
                exit={get_initials(i).exit}
                animate={current_animation(i)}
                //   exit="exit"
                transition={{
                  x: { type: "spring", stiffness: 300, damping: 30 },
                  opacity: { duration: 0.2 },
                }}
                drag="x"
                dragConstraints={{ left: 0, right: 0 }}
                dragElastic={1}
                onDragEnd={(e: any, { offset, velocity }: any) => {
                  const swipe = swipePower(offset.x, velocity.x);

                  if (swipe < -SWIPE_CONFIDENCE_THRESHOLD) {
                    paginate(1);
                  } else if (swipe > SWIPE_CONFIDENCE_THRESHOLD) {
                    paginate(-1);
                  }
                }}
                pointerEvents={when(cardIndex === i, "auto", "none")}
                onAnimationStart={() => {
                  setIsRunning(true);
                  console.log("Animation started");
                }}
                onAnimationComplete={() => {
                  debounce(() => setIsRunning(false), "isAnimationRunning", 300)();
                  console.log("Animation completed");
                }}
              >
                <AssetWallet
                  id="asset-wallet"
                  minH="230px"
                  minW="480px"
                  maxW="480px"
                  coin={coin}
                  value={getBalance(coin)}
                  isLoading={isLoading}
                  canBeHidden
                  isBalanceHidden={state?.isHidden}
                  toggleHideBalance={toggleBalanceVisibility}
                  //   minW={{ base: "280px", smx: "290px", sm: "100%", "1sm": "100%", md: "540px" }}
                  //   tokens={tokens}
                  //   currency={coin as any}
                  onWithdraw={() => {
                    if (!isFiat(coin)) return navigate(`/wallet?tab=crypto&coin=${coin}&subpage=Crypto`);
                    else {
                      // return open("fiat:withdraw", { coin })();
                      return when(isWithdrawFiatDisabled, dopen("featureUnavailable"), open("fiat:withdraw", { coin }))();
                    }
                  }}
                  onFund={() => {
                    if (!isFiat(coin)) return navigate(`/wallet?tab=crypto&coin=${coin}&subpage=Crypto`);
                    else {
                      // return open("fiat:fund", { coin })();
                      return when(isFundingDisabled(coin), dopen("featureUnavailable"), open("fiat:fund", { coin }))();
                    }
                  }}
                  _withdraw={
                    {
                      // display: when(isFiat(coin), "flex", "none"),
                      // disabled: !canWithdrawWallet(coin) && isFiat(coin),
                      // onClick: when(isWithdrawFiatDisabled, dopen("featureUnavailable"), open("fiat:withdraw", { coin })),
                    }
                  }
                  _fund={{
                    // display: when(isFiat(coin), "flex", "none"),
                    disabled: isFundingDisabled(coin),
                    // onClick: when(isDepositFiatDisabled, dopen("featureUnavailable"), open("fiat:fund", { coin })),
                    // onClick={() => onOpen({ key: "Crypto", data: { coin: crypto?.coin } })}
                  }}
                />
              </MotionBox>
            ))}
          </AnimatePresence>

          {/* <motion.div
            onAnimationStart={() => console.log("Animation started")}
            onAnimationComplete={() => console.log("Animation completed")}
          /> */}

          <MotionBox pos="absolute" left="-6px" top="50%" transform="translateY(-50%)">
            <Button
              p="0"
              h="fit-content"
              minW="fit-content"
              minH="fit-content"
              variant="unstyled"
              _focus={{ shadow, borderColor }}
              _active={{ shadow, borderColor, transform: "scale(0.9)", transition: "all .3s ease-in-out" }}
              onClick={() => paginate(-1)}
              disabled={!!is_animation_running}
            >
              <Icon type="circleLeftArrow" />
            </Button>
          </MotionBox>
          <MotionBox pos="absolute" right="-6px" top="50%" transform="translateY(-50%)">
            <Button
              p="0"
              h="fit-content"
              minW="fit-content"
              minH="fit-content"
              variant="unstyled"
              _focus={{ shadow, borderColor }}
              _active={{ shadow, borderColor, transform: "scale(0.9)", transition: "all .3s ease-in-out" }}
              onClick={() => paginate(1)}
              disabled={!!is_animation_running}
            >
              <Icon type="circleLeftArrow" transform="rotate(-180deg)" />
            </Button>
          </MotionBox>

          <Box mt="-4px !important" display="flex" gridGap="4px" pos="absolute" bottom="-32px">
            {Array(totalPages)
              .fill(0)
              .map((_, i) => (
                <motion.div
                  key={`wallets-controller-indicator-${i}`}
                  style={{ background: "transparent" }}
                  initial={{ width: "10px", transformOrigin: "center" }}
                  animate={{ width: w(i), transformOrigin: "center" }}
                  exit={{ width: "10px", transformOrigin: "center" }}
                >
                  <Box as="button" w="100%" h="4px" borderRadius="50px" bg={bg(i)} /*onClick={() => onIndicatorClick(i)}*/ />
                </motion.div>
              ))}
          </Box>
        </MotionBox>
      </MotionBox>

      <FeatureUnavailableModal isOpen={isOpen("featureUnavailable")} onClose={close("featureUnavailable")} />
    </Group>
  );
}
