import { Box } from "@chakra-ui/react";
import { navigate } from "@reach/router";
import { useGetUserCardProfileQuery, useGetUserCardsQuery } from "apis";
import { ConditionalRender, CreditCardType, PageLoading, Steps } from "components";
import { useAppConfig } from "contexts/appconfig.context";
import { useDisclosures, usePartialState } from "hooks";
import { PageProps } from "interfaces";
import { useEffect, useMemo } from "react";
import MaxCardRequestReach from "ui/Cards/Modals/MaxCardReach";
import StepOne from "./StepOne";
import StepThree from "./StepThree";
import StepTwo from "./StepTwo";
import StepFour from "./StepFour";
import OptionalStepTwo from "./OptionalStepTwo";

interface RequestVirtualCardFeatureProps extends PageProps {}

interface IState {
  card: CreditCardType;
  currency: string;
  type: string;
  wallet: string;
  pin: string;
  brand: "visa" | "mastercard";
  cardType: CreditCardType;
  cardValue: string;
  cardProvider: string; // can be any one of these p2, p3, p4...

  amount: string;
  agreedToLimitations: boolean;

  extraData: {
    idType?: string;
    state?: string;
    idNumber?: string;
    bvn?: string;
    lga?: string;
    dob?: string;
    iovationBlackbox?: string;
    occupation?: string;
    annualSalary?: number;
    expectedMonthlyVolume?: string;
  };
}

export interface StepProps {
  state: ReturnType<typeof usePartialState<IState>>[0];
  set: ReturnType<typeof usePartialState<IState>>[1];
  reset: ReturnType<typeof usePartialState<IState>>[2];
  user?: PageProps["user"];
}

type ModalTypes = "max_card_request";
export default function RequestVirtualCardFeature(props: RequestVirtualCardFeatureProps) {
  const { user } = props;
  const [state, set, reset] = usePartialState<IState>({ type: "virtual", currency: "usd", amount: "5" });

  const { data, isLoading } = useGetUserCardsQuery("");
  const { isOpen, open, close } = useDisclosures<ModalTypes>();

  const { appfigs } = useAppConfig();
  const { cardConfig } = appfigs ?? {};

  console.log("Virtual Card State", state);

  const { data: cardProfile } = useGetUserCardProfileQuery(state?.cardProvider!, {
    skip: !state?.cardProvider || (!!state?.cardProvider && !["p5"].includes(state?.cardProvider)),
  });

  console.log("Card Profile", cardProfile);

  // user can create upto 5 mastercards and upto 3 visa cards,
  // TODO: review this condition
  const hasReachMaxCards = useMemo(
    () => (data?.totalCount ?? 0) >= Number(cardConfig?.maxCardHoldingPerUser ?? 8),
    [cardConfig?.maxCardHoldingPerUser, data?.totalCount]
  );
  console.log(hasReachMaxCards);

  const requires_card_profile = useMemo(() => {
    const { missingFields, status } = cardProfile ?? {};
    // if (["approved"].includes(status)) return false;
    // const is_known_status = ["", "declined", "pending"].includes(status) && state?.cardProvider === "p5";
    const needs_missing_fields = (missingFields ?? []).length > 0 || ["", "declined", "pending"].includes(status);
    // console.log("Card Profile", { missingFields, status, needs_missing_fields, is_known_status });
    if (["p5"].includes(state?.cardProvider ?? "p3") && !!needs_missing_fields) return true;
    return false;
  }, [cardProfile, state?.cardProvider]);

  const isStepOneDisabled = useMemo(() => !state?.brand || requires_card_profile, [state?.brand, requires_card_profile]);
  const isStepTwoDisabled = useMemo(() => !state?.card || requires_card_profile, [state?.card, requires_card_profile]);
  const isStepThreeDisabled = useMemo(
    () => !(state?.type && state?.currency && state?.wallet) || requires_card_profile,
    [state, requires_card_profile]
  );
  const isStepFourDisabled = useMemo(
    () => !state?.pin || (!!state?.pin && state?.pin?.length < 6) || requires_card_profile,
    [state?.pin, requires_card_profile]
  );

  console.log("Requires card profile", requires_card_profile);

  const handleClose = () => {
    close("max_card_request")();
    navigate(-1);
  };

  useEffect(() => {
    // if (hasReachMaxCards && !isLoading) navigate(-1);
    if (hasReachMaxCards && !isLoading) open("max_card_request")();
  }, [hasReachMaxCards, isLoading, open]);

  return (
    <Box>
      <ConditionalRender shouldRender={!isLoading && !hasReachMaxCards}>
        {/* <ConditionalRender shouldRender={true}> */}
        <Steps>
          <StepOne {...{ state, set, reset, user }} buttonProps={{ next: { disabled: isStepOneDisabled } }} />
          {requires_card_profile && (
            <OptionalStepTwo {...{ state, set, reset, user }} buttonProps={{ next: { disabled: requires_card_profile } }} />
          )}
          <StepTwo {...{ state, set, reset, user }} buttonProps={{ next: { disabled: isStepTwoDisabled } }} />
          <StepThree {...{ state, set, reset, user }} buttonProps={{ next: { disabled: isStepThreeDisabled } }} />
          <StepFour {...{ state, set, reset, user }} buttonProps={{ next: { disabled: isStepFourDisabled } }} />
        </Steps>
      </ConditionalRender>

      <ConditionalRender shouldRender={isLoading}>
        <PageLoading />
      </ConditionalRender>

      <MaxCardRequestReach isOpen={isOpen("max_card_request")} onClose={handleClose} />
    </Box>
  );
}
