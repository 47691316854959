import { useDisclosure, useToast } from "@chakra-ui/react";
import { createContext, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { clearError, dehydrate, selectAuth, selectError } from "store/slices";

import { useDebounce } from "hooks";
import toLower from "lodash/toLower";
import { AuthorizationModal } from "ui";

import configs, { isProd } from "config";
import isEmpty from "lodash/isEmpty";
import isEqual from "lodash/isEqual";
import omit from "lodash/omit";
import { isSafeRedirect } from "utils/urls";
import { ErrorType } from "interfaces";
// import capitalize from "lodash/capitalize";

// import EventEmitter from "lib/EventEmitter";

interface IErrorContext {
  toast: ReturnType<typeof useToast>;
}

const ErrorContext = createContext<Partial<IErrorContext>>({});

const msgFilters = [
  "no referrals",
  "typeError: failed to fetch",
  "chat session not found",
  "no ongoing wishlist matching current selection",
  "<!DOCTYPE html>",
  "Unable to fetch crypto prices",
  "Unauthorized. Token invalid. Please login",
  "No kyc profile for this method was found", // suppress sumsub's kyc status check error
];

const tranformError = ({ message, action }: ErrorType) => {
  const msg_is_object = typeof message === "object";
  const is_service_unavailable =
    !msg_is_object && ((message ?? "").includes("503") || (message ?? "").includes("500") || (message ?? "").includes("<html>"));

  if (is_service_unavailable) {
    const service = (action?.type ?? "").split("/")[0] ?? "";
    return `${service} service is temporarily unavailable`.trimStart();
  }

  if (typeof message === "object") return "Something went wrong";
  return message;
};

// let count = 0;
const ErrorContextProvider = (props: any) => {
  const toast = useToast();
  const dispatch = useDispatch();
  const redebounce = useDebounce();
  const [loggedOut, setLoggedOut] = useState(false);
  const { isOpen, onClose } = useDisclosure();

  // const { isOpen, close } = useInactivityMonitor(0.5);

  // console.log("AuthorizationModal", { isOpen: isOpen("authorization"), count: count++ });

  const { next } = useSelector(selectError);
  const { payload } = useSelector(selectAuth);
  const prevError = useRef<Omit<typeof next, "id" | "action"> | null>(null);

  console.log("Next Error", next);

  const hasFilters = useMemo(
    () =>
      !!next?.message &&
      msgFilters.filter(
        (msg) => toLower(msg) === toLower(next?.message ?? "") || toLower(next?.message ?? "").includes(toLower(msg))
      ).length > 0,
    [next]
  );

  // console.log("Next Error Message", next?.message, hasFilters);

  const autoLogOut = useCallback(() => {
    if (
      ((next?.message && toLower(next.message).includes("authorization failed")) ||
        (next?.message && toLower(next.message).includes("token invalid")) ||
        (next?.message && toLower(next.message).includes("unauthorized"))) &&
      2 + 2 === 5
    ) {
      dispatch(dehydrate());
      console.log("[auth]", "called autoLogout");

      const continueTo = window.location.pathname;

      const isSafeRto = isSafeRedirect({ url: continueTo!, skip: !isProd });
      const isExempted = (path: string) => [configs.paths.login, configs.paths.register].includes(path);

      if (continueTo && isSafeRto && !isExempted(continueTo)) {
        window.location.replace(`${configs.paths.login}?rto=${continueTo}`);
      } else if (!isExempted(window.location.pathname)) {
        window.location.replace(configs.paths.login);
      }

      // navigate(`${config.paths.login}?rto=${continueTo}`, { replace: true });
      setLoggedOut(true);
    }
  }, [next, dispatch]);

  const hasErrorValues = useMemo(() => !isEmpty(Object.values(next).filter(Boolean)), [next]);
  // console.log("Next Error", next, hasErrorValues);

  const handleClose = () => {
    // close("authorization")();
    onClose();
    window.localStorage.setItem("lastActive", new Date().toISOString());
    // setLoginPasswordOverlayOpen(false);
  };

  // const handleOpen = useCallback(() => {
  //   onOpen();
  //   // setLoginPasswordOverlayOpen(true);
  // }, [onOpen]);

  useEffect(() => {
    if (hasErrorValues && !!next?.message) {
      // autoLogOut();
      redebounce(() => autoLogOut(), "autoLogout", 500)();
      if (!isEqual(prevError.current, omit(next, ["id", "action"])) && !hasFilters) {
        // console.log("NEXT ERROR", next);

        // const message = toLower(next.message);
        // const openAuthorization = () => onOpen();

        // if (
        //   (message.includes("unauthorized. token invalid") || message.includes("please login")) &&
        //   !loggedOut &&
        //   !!payload?.isSignedIn
        // ) {
        //   console.log("SHOULD HAVE OPENED THE AUTHORIZATION MODAL");
        //   redebounce(() => handleOpen(), "onOpen", 500)();
        // }
        //   const type = next?.action?.type;
        //   EventEmitter.emit("error", next, type);

        toast({
          position: "bottom-right",
          title: "Error",
          description: tranformError(next),
          status: "error",
          duration: 9000,
          isClosable: true,
          onCloseComplete: () => {
            dispatch(clearError("next"));
            prevError.current = null;
            // setTimeout(() => (prevError.current = null), 1000);
          },
        });

        prevError.current = omit(next, ["id", "action"]);
      }
    }
  }, [next, hasFilters, dispatch, toast, autoLogOut, redebounce, hasErrorValues, loggedOut, payload?.isSignedIn]);

  return (
    <ErrorContext.Provider value={{ toast }}>
      {props.children}
      <AuthorizationModal isOpen={isOpen} onClose={handleClose} />
    </ErrorContext.Provider>
  );
};

export default ErrorContextProvider;
