import { LeftArrowSVG } from "assets";
import { useRequestVerificationCode, useSelector } from "hooks";
import { selectUser } from "store/slices";

import { AbstractModal, Logo, SendOTP, SendViaWhatsapp, Steps, ToggleSmsOrCallChannel, useModalView } from "components";
import { useMemo, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  InputGroup,
  InputRightElement,
  ModalProps,
  Text,
  useColorModeValue,
  useMediaQuery,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { useForgot2FAMutation, useGetSecurityQuestionQuery, useSaveSecurityQuestionMutation } from "apis";
import Input from "components/Input/Input";

interface Reset2FAProps extends Omit<ModalProps, "children"> {}

export function Reset2FAModal(props: Reset2FAProps) {
  const { isOpen, onClose } = props;

  const [isMobile] = useMediaQuery("(max-width: 767px)");

  return (
    <AbstractModal
      isOpen={isOpen}
      onClose={onClose}
      _content={{
        maxW: "550px",
        borderRadius: isMobile ? "0" : "20px",
      }}
      size={{ base: "full", "2sm": "550px" }}
      closeOnEsc={false}
      closeOnOverlayClick={false}
    >
      <ResetSteps {...props} />
    </AbstractModal>
  );
}

interface ResetStepsProps extends Reset2FAProps {}

function ResetSteps(props: ResetStepsProps) {
  const { onClose: _onClose } = props;

  const { profile } = useSelector(selectUser);

  const [steps, setStep] = useState("");

  const logoColor = useColorModeValue("primary.default", "secondary.500");
  const bColor = useColorModeValue("grey.500", "gray.100");
  const successMessageColor = useColorModeValue("grey.500", "grey.150");
  const hoverColor = useColorModeValue("black", "white");
  const [isMobile] = useMediaQuery("(max-width: 767px)");
  const { data, isLoading } = useGetSecurityQuestionQuery(
    {}
    // {
    //   skip: !(isOpen && steps === "oops"),
    // }
  );

  const hasSecurityQuestion = useMemo(() => !!data?.question, [data]);

  console.log("Security Question", data);

  const handleStep = (step: string) => {
    setStep(step);
  };
  const [state, setState] = useState({
    securityQuestion: "",
    securityAnswer: "",
    code: "",
  });
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    setState({
      ...state,
      [name]: value,
    });
  };

  const onClose = () => {
    setStep("");
    _onClose();
  };

  return (
    <VStack
      p={isMobile ? "48px 18px" : "48px"}
      maxWidth={isMobile ? "425px" : "initial"}
      margin="auto"
      alignItems={isMobile ? "flex-start" : "center"}
    >
      {isMobile && (
        <Box onClick={onClose} mb="22px">
          <LeftArrowSVG />
        </Box>
      )}
      <Logo fontSize="54px" mb="16px" _name={{ w: "110px" }} color={logoColor} />

      <Box w="100%">
        <Steps hideIndicator>
          <Index isMobile={isMobile} color={successMessageColor} onClick={() => handleStep("promise")} profile={profile} />
          <PromiseStep
            isMobile={isMobile}
            color={successMessageColor}
            onClick={() => handleStep(!!data?.question ? "securityQuestion" : "oops")}
          />
          {!hasSecurityQuestion && (
            <OoopsStep isMobile={isMobile} color={successMessageColor} onClick={() => handleStep("securityQuestion")} />
          )}

          <CreateSecurityQuestionStep
            isMobile={isMobile}
            state={state}
            handleInputChange={handleInputChange}
            color={successMessageColor}
            onClick={() => handleStep("one-time")}
            isLoadingSecurityQuestion={isLoading}
            securityQuestionData={data}
          />

          <OnetTimeCodeStep
            isMobile={isMobile}
            handleInputChange={handleInputChange}
            state={state}
            color={successMessageColor}
            onClick={() => handleStep("hurray")}
            onClose={onClose}
            goBack={() => handleStep("securityQuestion")}
          />
          <HurrayStep color={successMessageColor} isMobile={isMobile} />
        </Steps>
      </Box>

      {/* {steps === "" && (
        <Index isMobile={isMobile} color={successMessageColor} onClick={() => handleStep("promise")} profile={profile} />
      )} */}

      {/* {steps === "promise" && (
        <PromiseStep
          isMobile={isMobile}
          color={successMessageColor}
          onClick={() => handleStep(!!data?.question ? "securityQuestion" : "oops")}
        />
      )} */}

      {/* {steps === "oops" && (
        <OoopsStep isMobile={isMobile} color={successMessageColor} onClick={() => handleStep("securityQuestion")} />
      )} */}

      {/* {steps === "securityQuestion" && (
        <CreateSecurityQuestionStep
          isMobile={isMobile}
          state={state}
          handleInputChange={handleInputChange}
          color={successMessageColor}
          onClick={() => handleStep("one-time")}
          isLoadingSecurityQuestion={isLoading}
          securityQuestionData={data}
        />
      )} */}

      {/* {steps === "one-time" && (
        <OnetTimeCodeStep
          isMobile={isMobile}
          handleInputChange={handleInputChange}
          state={state}
          color={successMessageColor}
          onClick={() => handleStep("hurray")}
          onClose={onClose}
          goBack={() => handleStep("securityQuestion")}
        />
      )} */}

      {/* {steps === "hurray" && <HurrayStep color={successMessageColor} isMobile={isMobile} />} */}

      <Box
        textAlign="center"
        textDecor="underline"
        cursor="pointer"
        minW="unset"
        w="100%"
        borderColor={bColor}
        onClick={onClose}
        _hover={{ color: hoverColor }}
        fontSize="16px"
        fontWeight="500"
        color="#0D4740"
      >
        {steps === "hurray" ? "Close" : "Cancel"}
      </Box>
    </VStack>
  );
}

// TODO: Move Index, Promise, Oooops, CreateSecurityQuestion
// TODO: and OneTimeCode to separate files
interface StepProps {
  color: string;
  onClick: () => void;
  profile?: any;
  isMobile: boolean;
}

function Index({ color, onClick, profile, isMobile }: StepProps) {
  const { onNext } = useModalView();
  return (
    <Box maxWidth="398px" width="100%" margin={isMobile ? "initial" : "auto"} textAlign={isMobile ? "left" : "center"}>
      <Box>
        <Heading as="h4" fontSize="24px" fontWeight="600" mb="30px !important">
          Hi {profile?.firstName}
        </Heading>

        <VStack color={color} mb="35px !important" fontWeight="500">
          <Text>We are sorry to learn that you are encountering issues with your two factor authentication.</Text>
          <Text>Now let us help you get back your account without compromising your security.</Text>
        </VStack>
      </Box>

      <VStack w="100%">
        <Button minW="initial" maxW={isMobile ? "336px" : "399px"} width="100%" mb="45px !important" onClick={onNext}>
          Yes, Proceed
        </Button>
      </VStack>
    </Box>
  );
}

function PromiseStep({ color, onClick, isMobile }: StepProps) {
  const { onNext } = useModalView();
  return (
    <Box maxWidth="398px" width="100%" margin={isMobile ? "initial" : "auto"} textAlign={isMobile ? "left" : "center"}>
      <Heading as="h4" fontSize="24px" fontWeight="600" mb="30px !important">
        Awesome!!!
      </Heading>

      <Box maxWidth="432px" width="100%" margin={isMobile ? "initial" : "auto"}>
        <VStack color={color} mb="35px !important" fontWeight="500" alignItems={isMobile ? "flex-start" : "center"}>
          <Text>
            We will ask you a very simple question that only you should be able to answer. If you have never shared your secret
            question with us before, you will be able to do so only this one time.
          </Text>

          <Text>Promise us you will not forget this one.</Text>
        </VStack>
      </Box>

      <VStack w="100%">
        <Button minW="initial" maxW={isMobile ? "336px" : "399px"} width="100%" w="100%" mb="45px !important" onClick={onNext}>
          I Promise
        </Button>
      </VStack>
    </Box>
  );
}

function OoopsStep({ color, onClick, isMobile }: StepProps) {
  const { onNext } = useModalView();
  return (
    <Box maxWidth="432px" width="100%" margin={isMobile ? "initial" : "auto"} textAlign={isMobile ? "left" : "center"}>
      <Heading as="h4" fontSize="24px" fontWeight="600" mb="30px !important">
        Oops!!! This is your first time.
      </Heading>

      <VStack color={color} mb="35px !important" fontWeight="500" textAlign={isMobile ? "left" : "center"}>
        <Text>You need to create your security question and answer, then we will resume the previous step</Text>
      </VStack>

      <VStack w="100%">
        <Button minW="initial" maxW={isMobile ? "336px" : "399px"} width="100%" w="100%" mb="45px !important" onClick={onNext}>
          Proceed
        </Button>
      </VStack>
    </Box>
  );
}

interface InputProps {
  securityQuestion: string;
  securityAnswer: string;
  code: string;
}

interface SecurityQuestionProps extends StepProps {
  handleInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  state: InputProps;
  onClose?: any;
  goBack?: any;
  securityQuestionData?: any;
  isLoadingSecurityQuestion?: boolean;
  hasSecurityQuestion?: boolean;
}

function CreateSecurityQuestionStep({
  color,
  onClick,
  handleInputChange,
  state,
  isMobile,
  isLoadingSecurityQuestion,
  securityQuestionData,
}: SecurityQuestionProps) {
  const toast = useToast();
  const data = securityQuestionData;
  const isLoading = isLoadingSecurityQuestion;

  const { onNext: _onNext } = useModalView();

  const onNext = (e?: any) => {
    e.preventDefault();
    _onNext();
  };

  const [_saveSecurityQuestion, { isLoading: isSaveLoading }] = useSaveSecurityQuestionMutation();

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    try {
      const response: any = await _saveSecurityQuestion({
        ...state,
      });

      if (response.data) {
        toast({
          position: "bottom-right",
          title: "Success",
          description: response.data.message,
          status: "success",
          duration: 9000,
          isClosable: true,
        });
        _onNext();
      }
    } catch (error) {
      // onNext();
    }
  };

  return (
    <Box w="100%">
      <Heading as="h4" fontSize="24px" fontWeight="600" my="20px !important">
        {!!data?.question ? "Security Question" : " Create Security Question"}
      </Heading>

      <Text fontWeight="500" fontSize="16px" mb="33px !important" color={color}>
        {!!data?.question ? "Provide" : "And provide"} an answer to your security question below
      </Text>

      <Text mb="30px !important" textAlign="center">
        {data?.question}
      </Text>

      {isLoading ? (
        <CircularProgress isIndeterminate color="green.300" />
      ) : (
        <Box as="form" w="100%" onSubmit={data?.question ? onNext : handleSubmit}>
          <VStack spacing="23px" w="100%">
            {!!data?.question ? (
              <FormControl>
                <FormLabel htmlFor="securityAnswer">Security Answer</FormLabel>
                <Input
                  isRequired
                  type="text"
                  id="securityAnswer"
                  name="securityAnswer"
                  placeholder="Enter your security answer here"
                  onChange={handleInputChange}
                />
              </FormControl>
            ) : (
              <>
                <FormControl>
                  <FormLabel htmlFor="securityQuestion">Security Question</FormLabel>

                  <Input
                    isRequired
                    type="text"
                    id="securityQuestion"
                    name="securityQuestion"
                    placeholder="Enter your security question here"
                    onChange={handleInputChange}
                  />
                </FormControl>

                <FormControl>
                  <FormLabel htmlFor="securityAnswer">Security Answer</FormLabel>
                  <Input
                    isRequired
                    type="text"
                    id="securityAnswer"
                    name="securityAnswer"
                    placeholder="Enter your security answer here"
                    onChange={handleInputChange}
                  />
                </FormControl>
              </>
            )}
          </VStack>

          <VStack w="100%" mt="58px">
            <Button
              isLoading={isSaveLoading}
              disabled={isSaveLoading}
              minW="initial"
              type="submit"
              maxW={isMobile ? "336px" : "399px"}
              width="100%"
              w="100%"
              mb="45px !important"
            >
              Submit
            </Button>
          </VStack>
        </Box>
      )}
    </Box>
  );
}

const RESET_2FA_COUNTDOWN_KEY = "r2ck";

function OnetTimeCodeStep({ color, handleInputChange, state, onClose, onClick, goBack, isMobile }: SecurityQuestionProps) {
  const toast = useToast();
  // const themeColor = useColor();
  // const initialTimeRef = useRef<any>();
  // const [initialTime, setInitialTime] = useState(0);
  // const [timerStarted, setTimerStarted] = useState(false);
  // const [delivery, setDelivery] = useState<"sms" | "call">("sms");

  const { onNext } = useModalView();

  const [_sendCode, { isLoading }] = useForgot2FAMutation();
  const [_submitCode, { isLoading: isSubmitLoading }] = useForgot2FAMutation();

  const useRequestCode = useRequestVerificationCode({
    defaultChannel: "sms",
    timerKey: RESET_2FA_COUNTDOWN_KEY,
  });
  const { channel: delivery, isChannelAvailable, switchChannel, timedout, started } = useRequestCode || {};

  // const { startCountdown, started, toTimeString } = usePersistentCountdown(RESET_2FA_COUNTDOWN_KEY);

  // const toggleDelivery = () => {
  //   if (delivery === "call") setDelivery("sms");
  //   else setDelivery("call");
  // };

  // const btnLabel = useMemo(() => (delivery === "call" ? "Dial" : "Send Code"), [delivery]);

  // const handleSendCode = async () => {
  //   try {
  //     const response: any = await _sendCode({
  //       securityAnswer: state.securityAnswer,
  //       call: delivery === "call" ? true : false,
  //     });

  //     if (!!response.data) {
  //       if (response.data?.data?.nextRequestInSecs) {
  //         console.log("Code response", response.data);
  //         initialTimeRef.current && clearTimeout(initialTimeRef.current);
  //         // setInitialTime(response.data?.data?.nextRequestInSecs);
  //         startCountdown(response.data?.data?.nextRequestInSecs);
  //       } else {
  //         initialTimeRef.current && clearTimeout(initialTimeRef.current);
  //         // setInitialTime(600);
  //       }
  //       // setTimerStarted(true);

  //       toast({
  //         position: "bottom-right",
  //         title: "Success",
  //         description: response.data.message,
  //         status: "success",
  //         duration: 9000,
  //         isClosable: true,
  //       });
  //     }

  //     if (response?.error) {
  //       goBack();
  //     }
  //   } catch (error) {}
  // };

  const handleRequest = async (channel?: string, startCountdown?: (secs: number) => void) => {
    // const captcha = await captchRef.current?.executeAsync();
    // captchRef.current?.reset();

    // const result = await dispatch(
    //   requestPhoneCode({ channel: (channel ?? delivery) as any, delivery: (channel ?? delivery) as any, humanKey: captcha })
    // );

    const result = await _sendCode({
      securityAnswer: state.securityAnswer,
      call: (channel ?? delivery) as any,
      channel: (channel ?? delivery) as any,
      delivery: (channel ?? delivery) as any,
    }).unwrap();

    // setDate(new Date(new Date().setSeconds(600)));
    console.log("Reset 2FA verification code result", result);
    if (!!result) {
      const next_countdown_time_sec = result?.data?.nextRequestInSecs || 600;
      startCountdown?.(next_countdown_time_sec);
    }
  };

  const handleSubmitCode = async (e: any) => {
    e.preventDefault();
    try {
      const response: any = await _submitCode({
        securityAnswer: state.securityAnswer,
        code: state.code,
        delivery,
      });

      if (response.data) {
        onClose()!;
        onNext();
        toast({
          position: "bottom-right",
          title: "Success",
          description: response?.data?.message,
          status: "success",
          duration: 9000,
          isClosable: true,
        });
      }
    } catch (error: any) {
      toast({
        position: "bottom-right",
        description: error?.message,
        status: "success",
        duration: 9000,
        isClosable: true,
      });
    }
  };

  // useEffect(() => {
  //   let timer: any;
  //   if (initialTime > 0 && timerStarted) {
  //     setTimeout(() => {
  //       setInitialTime(initialTime - 1);
  //     }, 1000);
  //   }

  //   if (initialTime === 0 && timerStarted) {
  //     setTimerStarted(false);
  //   }

  //   return () => {
  //     !!timer && clearTimeout(timer);
  //   };
  // }, [initialTime, timerStarted]);

  return (
    <>
      <Heading as="h4" fontSize="24px" fontWeight="600" my="20px !important">
        Security Verification
      </Heading>

      <Text fontWeight="500" fontSize="16px" mb="33px !important" color={color} textAlign={isMobile ? "center" : "left"}>
        To secure your account, request a confirmation code to your registered phone number and provide the code in the input below
      </Text>

      <Box as="form" w="100%">
        <VStack spacing="23px" w="100%">
          <FormControl>
            <FormLabel htmlFor="code">One Time Code</FormLabel>
            <InputGroup>
              <Input
                isRequired
                type="text"
                id="code"
                minW="unset"
                name="code"
                onChange={handleInputChange}
                placeholder="Reset Confirmation Code"
              />

              <InputRightElement pr="4rem">
                {/* {started ? (
                  // <Text ref={initialTimeRef}>{convertNumberMS(String(initialTime))}</Text>
                  <Text ref={initialTimeRef}>{toTimeString()}</Text>
                ) : (
                  <Button
                    _hover={{ bg: "#E2F2DA" }}
                    bg="#E2F2DA"
                    minW="102px"
                    minH="39px"
                    borderRadius="8px"
                    width="102px"
                    color="#0D4740"
                    fontWeight="700"
                    isLoading={isLoading}
                    disabled={isLoading}
                    onClick={handleSendCode}
                  >
                    {btnLabel}
                  </Button>
                )} */}

                <SendOTP
                  variant="max"
                  isLoading={isLoading}
                  timeStart={0}
                  timerKey={RESET_2FA_COUNTDOWN_KEY}
                  channel={delivery}
                  sendOtp={(startCountdown) => handleRequest(delivery, startCountdown)}
                  isDisabled={(!timedout && started) || isLoading}
                  fontSize="12px"
                  _hover={{ bg: "#E2F2DA" }}
                  bg="#E2F2DA"
                  minW="102px"
                  minH="39px"
                  borderRadius="8px"
                  width="102px"
                  color="#0D4740"
                  fontWeight="700"
                  useRequestCode={useRequestCode}
                />
              </InputRightElement>
            </InputGroup>

            <HStack px="4px" justifyContent="space-between">
              <ToggleSmsOrCallChannel
                channel={delivery ?? "none"}
                setChannel={switchChannel}
                isSmsOrCallChannelAvailable={isChannelAvailable(["call", "sms"])}
              />

              <SendViaWhatsapp
                channel={delivery ?? "none"}
                setChannel={switchChannel}
                isWhatsappChannelAvailable={isChannelAvailable(["whatsapp"])}
                // sendOtp={(s) => handleRequest(delivery, s)}
                isDisabled={(!timedout && started) || isLoading}
              />
            </HStack>

            {/* <AnimatePresence exitBeforeEnter initial={false}>
              <motion.div
                key={`delivery-${when(delivery === "sms", "sms", "call")}`}
                initial={{ opacity: 0, scale: 0.98 }}
                animate={{ opacity: 1, scale: 1 }}
                exit={{ opacity: 0, scale: 0.98 }}
              >
                <HStack
                  fontSize="14px"
                  fontWeight="600"
                  mt="10px !important"
                  as="a"
                  href="#"
                  color={themeColor("primary.default", "secondary.400")}
                  onClick={toggleDelivery}
                  textDecoration="underline"
                  minH="28px"
                >
                  {delivery === "sms" && (
                    <>
                      <Icon type="calling" color={themeColor("primary.default", "secondary.400")} />
                      <Text>Call me via phone instead</Text>
                    </>
                  )}

                  {delivery === "call" && <Text>Send code via sms.</Text>}
                </HStack>
              </motion.div>
            </AnimatePresence> */}
          </FormControl>
        </VStack>

        <VStack w="100%" mt="58px">
          <Button
            isLoading={isSubmitLoading}
            disabled={isSubmitLoading || state.code.length !== 6}
            type="submit"
            minW="initial"
            maxW={isMobile ? "336px" : "399px"}
            width="100%"
            mb="45px !important"
            onClick={handleSubmitCode}
          >
            Submit
          </Button>
        </VStack>
      </Box>
    </>
  );
}

interface HurrayProps {
  color: string;
  isMobile: boolean;
}
function HurrayStep({ color, isMobile }: HurrayProps) {
  return (
    <Box maxWidth="402px" width="100%" margin={isMobile ? "initial" : "auto"} textAlign={isMobile ? "left" : "center"}>
      <Heading as="h4" fontSize="24px" fontWeight="600" mb={{ base: "43px !important", "2sm": "30px !important" }}>
        Hurray!!! It's almost over
      </Heading>

      <Text color={color} mb="35px !important" fontWeight="500">
        We have sent you an email with a link to reset your 2FA. Click on the link in the mail to complete the reset or to generate
        a new 2FA secret.
      </Text>
    </Box>
  );
}
