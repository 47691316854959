import { Box, Button, Heading, ModalProps, Text, VStack } from "@chakra-ui/react";
import { navigate } from "@reach/router";
import { AbstractModal, Icon } from "components";

interface CardEligibilityProps extends Omit<ModalProps, "children"> {
  minimum_required_kyc_level?: number;
}

export default function CardEligibility(props: CardEligibilityProps) {
  const { isOpen, onClose, minimum_required_kyc_level, ...xprops } = props;
  //   const { colorMode } = useColorMode();

  const handleVerification = () => {
    navigate("/account/verification");
  };

  return (
    <AbstractModal
      isOpen={isOpen}
      onClose={onClose}
      closeOnEsc={false}
      closeOnOverlayClick={false}
      _content={{
        maxW: "560px",
        borderRadius: { base: "30px 30px 0 0", "1sm": "20px" },
        margin: 0,
        bottom: "0",
        position: { base: "absolute", "1sm": "relative" },
      }}
      {...xprops}
    >
      <Box p={{ base: "32px 40px", "1sm": "44px 80px" }}>
        <VStack>
          <Icon type="verificationCompleted" boxSize="44px" />
          <Heading as="h5" fontSize="2xl">
            Verify your Account
          </Heading>
        </VStack>

        <Text py="38px" textAlign="center" fontWeight="500">
          You need to verify your account to level ({minimum_required_kyc_level ?? 3}) three to be able to create cards
          {/* You need to verify your account to level (2) two to be able to create cards */}
        </Text>

        <VStack px="46px" w="100%">
          <Button minW="unset" w="100%" onClick={handleVerification}>
            Go to verification page
          </Button>
          <Button variant="transparent" textDecoration="underline" minW="unset" w="100%" onClick={onClose}>
            Close
          </Button>
        </VStack>
      </Box>
    </AbstractModal>
  );
}
