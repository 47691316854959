import { Button, Heading, ModalProps, Text, useColorMode, VStack } from "@chakra-ui/react";
import { AbstractModal, Icon } from "components";
import { switchStyle } from "utils";

interface GenerateBlackboxIssueProps extends Omit<ModalProps, "children"> {}

export default function GenerateBlackboxIssue(props: GenerateBlackboxIssueProps) {
  const { colorMode } = useColorMode();

  return (
    <AbstractModal _content={{ maxW: "506px" }} {...props}>
      <VStack px="60px" py="74px">
        <Icon type="infoErrorRounded" boxSize="30px" />

        <VStack my="24px !important" color={switchStyle(colorMode, { dark: "white", light: "black" })}>
          <Heading as="h4" fontSize="24px" color="inherit">
            Oops!
          </Heading>
          <Text
            mt="20px !important"
            textAlign="center"
            fontWeight="500"
            fontFamily="var(--bitmama-fonts-heading)"
            color="inherit"
            lineHeight="28px"
          >
            We couldn't generate a device fingerprint from your device. Please try using a compatible browser to continue accessing
            our services.
          </Text>
        </VStack>

        <Button mt="42px !important" onClick={props.onClose}>
          Close
        </Button>
      </VStack>
    </AbstractModal>
  );
}
