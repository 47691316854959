import { ComponentType, PropsWithChildren, useMemo } from "react";

import { Redirect as R, RouteComponentProps } from "@reach/router";

import config from "config";
import ls from "utils/secureStorage";
import { PageProps } from "interfaces";
import { useDispatch, useEventListener, useSelector } from "hooks";
import { getProfile, selectUser } from "store/slices";
// import { ViewPort } from "components";
import { IntercomProvider } from "react-use-intercom";
import configs from "config";

// import useUser from "hooks/useUser";

const Redirect = R as any;
interface ProtectedRouteProps extends RouteComponentProps {
  component: ComponentType<PageProps> | string;
  isNotMandatory?: boolean;
}

export default function ProtectedRoute(props: PropsWithChildren<ProtectedRouteProps>) {
  const { component: Component, isNotMandatory, ...rest } = props;
  const { status, profile } = useSelector(selectUser);
  const dispatch = useDispatch();
  // const { pathname } = useLocation();

  const isSignedIn = () => {
    const auth = ls.get(config.AUTH_TOKEN_KEY);
    return auth?.isSignedIn && auth?.token;
  };

  useEventListener("refetch_account_profile", () => dispatch(getProfile()));

  const isFetchingUser = useMemo(() => status === "fetching" || status === "loading", [status]);
  const user = useMemo(() => profile, [profile]);

  // console.log("User", user);

  const renderRoute = () => {
    if (isSignedIn())
      return (
        <IntercomProvider appId={configs.INTERCOM_TOKEN}>
          <Component isFetchingUser={isFetchingUser} user={user} {...rest} />
        </IntercomProvider>
      );
    return isNotMandatory ? (
      <Component isFetchingUser={isFetchingUser ?? false} {...rest} />
    ) : (
      <Redirect to={config.paths.login} noThrow />
    );
  };

  // return  renderRoute();
  return (
    <>
      {/* {pathname.includes("p2p") ? <ViewPort responsive={false} /> : <ViewPort responsive />} */}
      {renderRoute()}
    </>
  );
}
