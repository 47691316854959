import { useMemo, useCallback } from "react";

import { useGetWalletsQuery } from "apis";
import { SupportedCoinType } from "./useCoin";
import { useEventListener } from "./useInlineWebPush";
import { isFiat } from "utils";
import { useAppConfig } from "contexts/appconfig.context";
import { VersionedAddressRo, WalletAddressRo } from "interfaces";

const maskedCoins = [
  "usdt-tron",
  "usdt-matic",
  "usdc-matic",
  "usdc-stellar",
  "usdc-tron",
  "usdc-algo",
  "usdt-algo",
  "usdt-celo",
  "usdc-celo",
  "usdt-ton",
];

const useWalletBalance = () => {
  const { data: rawWallets, isLoading, refetch } = useGetWalletsQuery({});
  useEventListener("refetch_wallet", () => refetch());

  const { appfigs = {} } = useAppConfig();
  const { addressSystem = "v3" } = appfigs?.addressConfig || {};

  const wallets = useMemo(() => {
    /// Select wallet address based on the `addressSystem` config without breaking the app.
    ///  If addressSystem does not exists, treat as null. DO NOT fallback.
    const addresses = (rawWallets?.addresses ?? []).map((w) => {
      const systemAddr = w[addressSystem as keyof WalletAddressRo] as VersionedAddressRo;
      return {
        ...w,
        // v1_address: w.address,
        // address: when<string>(!!systemAddr, systemAddr?.address, !!w["v2"]?.address ? w["v2"]?.address : w?.address),
        ...systemAddr,
        address: systemAddr?.address ?? null,
      };
    });

    return { ...rawWallets, addresses };
  }, [rawWallets, addressSystem]);

  const currencies = useMemo(() => {
    if (wallets) {
      return {
        cryptos: wallets?.addresses?.filter((a) => a?.address && a?.coin !== "usdc-base"),
        // fiats: wallets?.addresses?.filter((a) => !a?.address && a?.coin),
        fiats: wallets?.addresses?.filter((a) => isFiat(a?.coin ?? "")),
      };
    }
  }, [wallets]);

  // console.log("CURRENCIES", currencies);

  const cryptos = useMemo(() => currencies?.cryptos?.filter((c) => !maskedCoins.includes(c?.coin)), [currencies]);

  const getCoin = useCallback(
    (coin: SupportedCoinType) => {
      if (wallets) {
        return wallets?.addresses?.find((a) => a?.coin === coin);
      }
    },
    [wallets]
  );

  const getBalance = useCallback((coin: SupportedCoinType) => +(getCoin(coin)?.balance?.$numberDecimal ?? 0), [getCoin]);

  return {
    isLoading,
    cryptos: cryptos ?? [],
    fiats: currencies?.fiats ?? [],
    getCoin,
    getBalance,
    refetch,
  };
};

export default useWalletBalance;
