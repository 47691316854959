import { Box, ModalProps, VStack } from "@chakra-ui/react";
import { AbstractModal } from "components";

interface SumsubKycModalProps extends Omit<ModalProps, "children"> {}

export default function SumsubKycModal(props: SumsubKycModalProps) {
  const { isOpen, onClose } = props;

  //   const { profile } = useSelector(selectUser);

  //   const [is_launched, setLaunched] = useState(false);

  //   // const handleSubmit = () => {
  //   //   onClose();
  //   // };

  //   const launch = useCallback(async () => {
  //     const { email, phone } = profile ?? {};
  //     if (!email || !phone) return;
  //     const access_token = await getSumsubNewAccessToken();
  //     console.log("Got here", { email, phone, access_token });

  //     const sdk = launchSumsubWebSdk({
  //       accessToken: access_token,
  //       applicantEmail: email,
  //       applicantPhone: phone,
  //       theme: "light",
  //       containerEl: "bitmama__sumsub",
  //     });

  //     if (!!sdk) setLaunched(true);
  //   }, [profile]);

  //   useEffect(() => {
  //     (async () => {
  //       if (!is_launched) {
  //         await launch();
  //       }
  //     })();
  //   }, [is_launched, launch]);

  return (
    <AbstractModal
      isOpen={isOpen}
      onClose={onClose}
      _content={{ maxW: "560px", overflow: "hidden", transition: "height 300ms", willChange: "height" }}
    >
      <VStack /*py="45px" px={{ base: "16px", md: "60px" }}*/>
        <VStack w="100%" h="100%">
          <Box w="100%" h="100%" id="bitmama__sumsub" pt="20px" pb="60px" bg="#fff"></Box>
        </VStack>

        {/* <VStack mt="38px !important" gridGap="16px" px={{ base: "0", md: "46px" }} w="100%">
          <Button minW={{ base: "300px", md: "400px" }} variant="transparent" textDecoration="underline" onClick={onClose}>
            Close
          </Button>
        </VStack> */}
      </VStack>
    </AbstractModal>
  );
}
